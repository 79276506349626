.container {
  max-width: 1200px;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
}

.text-white {
  color: #fff;
}

.btn-main,
.btn-light,
.btn-dark {
  display: inline-block;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}

.btn-main {
  color: #333;
  background-color: #ffbc00;
}

.btn-light {
  color: #333;
  background-color: #f4f4f4;
}

.btn-dark {
  color: #f4f4f4;
  background-color: #333;
}

.bg-main {
  background: #ffbc00;
}

.bg-light {
  background: #f4f4f4;
}

.bg-dark {
  background: #333;
}

.bg-medium {
  background: #ccc;
}

button[class^="btn-"]:hover,
a[class^="btn-"]:hover,
input[class^="btn-"]:hover {
  background-color: #ffbc00;
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

.py-4 {
  padding: 4rem 0;
}

.py-5 {
  padding: 5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}

.my-5 {
  margin: 5rem 0;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.section-title {
  font-size: 2rem;
  display: block;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
}

.bottom-line {
  height: 2px;
  width: 3rem;
  background: #ffbc00;
  display: block;
  margin: 0 auto;
}

* {
  font-family: "Dosis", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
  background: #fff;
}

a {
  text-decoration: none;
  color: #333;
}

ul {
  list-style: none;
}

h2,
h3,
h4 {
  text-transform: uppercase;
}

img {
  width: 100%;
}

#logo {
  width: auto;
  height: 70px;
  color: #fff;
  text-transform: uppercase;
}

#main-nav {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
#main-nav ul {
  display: flex;
}
#main-nav li {
  padding: 1rem 1.5rem;
}
#main-nav a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 3px transparent solid;
  padding-bottom: 0.5rem;
  transition: border-color 0.5s;
}
#main-nav a:hover {
  border-color: #ccc;
}
#main-nav a.current {
  border-color: #ffbc00;
}

#header-home {
  background: url("https://angel2h.com/dist/img/showcase.png") no-repeat center
    right/cover;
  height: 100vh;
  color: #fff;
}
#header-home .header-content {
  text-align: center;
  padding-top: 10%;
}
#header-home .header-content h1 {
  font-size: 4rem;
  line-height: 1.5;
}

#header-inner {
  background: url("https://angel2h.com/dist/img/showcase.png") no-repeat 20% 30% /
    cover;
  height: 5.5rem;
  border-bottom: 3px solid #ffbc00;
}

#home-a .specials {
  margin-top: 3rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}
#home-a .specials .fas,
#home-a .specials .fab {
  color: #ffbc00;
  padding-bottom: 0.4rem;
}

#home-b .stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
#home-b .stats li {
  line-height: 2;
}
#home-b .stats li.stats-title {
  font-size: 1.5rem;
}
#home-b .stats li.stats-number {
  font-size: 2rem;
  font-weight: bold;
}
#home-b .stats div {
  padding: 3rem 0;
}
#home-b .stats div:nth-child(odd) {
  background: #f4f4f4;
}
#home-b .stats div:nth-child(even) {
  background: #ccc;
}

#home-c .process {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  text-align: center;
}
#home-c .process-step {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 28px;
  background: #ffbc00;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  line-height: 15px;
  padding: 1rem;
  transition: all 1s;
}
#home-c .process-icon {
  border-radius: 50%;
  background: #333;
  color: #fff;
  padding: 2rem;
  width: 70px;
  height: 70px;
  line-height: 70px;
  position: relative;
  transition: all 1s;
}
#home-c .process-icon:hover {
  background: #ffbc00;
  width: 90px;
  height: 90px;
  line-height: 90px;
}
#home-c .process-icon:hover .process-step {
  background: #333;
}

#about-a .about-info {
  display: grid;
  grid-template-areas: "bioimage bio";
  grid-gap: 1.2rem;
}

#about-a .bio-image {
  grid-area: bioimage;
  width: 320px;
  height: 320px;
  background: url("https://angel2h.com/dist/img/angel.png") no-repeat center
    center/cover;
}

#about-a .bio {
  grid-area: bio;
  border-left: 3px solid #ffbc00;
  padding: 0.8rem;
}

#about-a .award-1 {
  grid-area: aw1;
}

#about-a .award-2 {
  grid-area: aw2;
}

#about-a .award-3 {
  grid-area: aw3;
}

#about-a .fa-award {
  color: #ffbc00;
  margin: 1rem;
}

#about-b {
  color: #fff;
}
#about-b .progress {
  overflow: hidden;
  height: 20px;
  background: #ccc;
  border-radius: 5px;
  margin-bottom: 0.6rem;
}
#about-b .progress div {
  height: 100%;
  background: #ffbc00;
  color: #fff;
  text-align: center;
}

#about-c .about-logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
}
#about-c .about-logos img {
  max-width: 60%;
  max-height: 70px;
}

#about-d .testimonials div {
  max-width: 220px;
  margin: auto;
}

#about-d .testimonials ul {
  list-style: none;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

#about-d .testimonials p {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem;
}

#about-d .testimonials img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.item {
  position: relative;
  background: #ffbc00;
  overflow: hidden;
}
.item::after {
  content: "";
  position: absolute;
  display: block;
  background: inherit;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
}
.item:hover:after {
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}
.item:hover .item-image {
  transform: scale(1.2);
}
.item:hover .item-text {
  opacity: 1;
  transform: translateY(0);
}
.item-image {
  height: auto;
  transform: translateZ(0);
  display: block;
  transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
}
.item-image:before {
  content: "";
  display: block;
  padding-top: 75%;
  overflow: hidden;
}
.item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  line-height: 0;
}
.item-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  transform: translateY(-20%);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 300ms;
}
.item-text-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.item-text-title {
  font-size: 2rem;
  padding: 0 1rem;
  margin: 5px 0 0 0;
}
.item-text-category {
  text-transform: uppercase;
  font-size: 1.2rem;
  opacity: 0.7;
  margin: 0;
}

#contact-a .text-fields {
  display: grid;
  grid-template-areas: "name email" "subject phone" "message message";
  grid-gap: 1.2rem;
  margin-bottom: 1.2rem;
}
#contact-a .text-fields .name-input {
  grid-area: name;
}
#contact-a .text-fields .subject-input {
  grid-area: subject;
}
#contact-a .text-fields .email-input {
  grid-area: email;
}
#contact-a .text-fields .phone-input {
  grid-area: phone;
}
#contact-a .text-fields .message-input {
  grid-area: message;
  height: 100px;
}
#contact-a .text-fields .text-input {
  padding: 0.5rem 1rem;
}
#contact-a .text-fields .text-input.invalid-input {
  border-color: red;
}

#contact-a button[type="submit"] {
  width: 70%;
}

#contact-b .contact-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

#contact-c p {
  font-size: 3rem;
}

#main-footer {
  background: #333;
  color: #fff;
  height: 5rem;
}
#main-footer .footer-content {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  align-items: center;
}
#main-footer .footer-content .social .fab {
  margin-right: 1rem;
  border: 2px #fff solid;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0.5rem;
}
#main-footer .footer-content .social .fab:hover {
  background: #ffbc00;
}

@media (max-width: 800px) {
  #header-home {
    height: 30rem;
  }
  #header-home .header-content {
    padding-top: 5rem;
  }
}

@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center;
  }
  #main-nav li {
    padding: 1rem;
  }
  #header-home {
    height: calc(100vh - 5rem);
    border-bottom: 3px solid #ffbc00;
    overflow-y: hidden;
  }
  #header-home .header-content {
    padding-top: 2rem;
  }
  #header-inner {
    height: 11rem;
  }
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr;
  }
  #home-a .specials div {
    border-bottom: 1px #f4f4f4 solid;
    padding-bottom: 1rem;
  }
  #home-a .specials div:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  #home-b .stats div {
    padding: 2rem 0 1rem 0;
  }
  #about-a .about-info {
    grid-template-areas: "bioimage" "bio";
  }
  #about-a .bio-image {
    height: 300px;
  }
  #about-c {
    display: none;
  }
  #contact-a .text-fields {
    grid-template-areas: "name" "email" "subject" "phone" "message";
  }
  #contact-b .contact-info div {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px #555 solid;
  }
  #contact-b .contact-info div:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  #main-footer {
    height: 7rem;
  }
  #main-footer .footer-content {
    flex-direction: column;
    padding: 1rem;
    height: 5rem;
  }
}

@media (max-height: 500px) {
  #header-home {
    overflow-y: hidden;
  }
  #header-home .header-content {
    padding-top: 2rem;
  }
}

@media (max-height: 330px) {
  #header-home {
    overflow-y: hidden;
  }
  #header-home .header-content {
    padding-top: 1rem;
  }
  #header-home .header-content h1 {
    font-size: 2rem;
  }
}
